import styled from 'styled-components';

const AboutPageWrapper = styled.div`
  margin-top: 80px;
  width: 100%;
  transition: ${props => props.theme.transition};
  background-color: ${props => props.theme.bgColor};
  min-height: 80vh;

  h1 {
    font-size: 2.7rem;
    color: ${props => props.theme.textColor};
  }

  @media (min-width: 800) {
    h1 {
      font-size: 3rem;
    }
  }
`;

export { AboutPageWrapper };
